import { createContext, useContext, useRef, useState } from 'react';
import ConfirmTemplate from './ConfirmTemplate';
import MessageTemplate from './MessageTemplate';

const TemplateContext = createContext();

export function useTemplate() {
    return useContext(TemplateContext);
}

function TemplateProvider({ children }) {
    const suggestRef = useRef();
    const [message, setMessage] = useState(null);
    const [confirm, setConfirm] = useState(null);

    const openConfirm = (icon, text, buttons) => {
        setConfirm({ icon, text, buttons });
    };
    const closeConfirm = () => {
        setConfirm(null);
    };

    const value = {
        message,
        setMessage,
        confirm,
        openConfirm,
        closeConfirm,
        suggestRef
    };

    return (
        <TemplateContext.Provider value={value}>
            {children}
            <MessageTemplate message={message} setMessage={setMessage} />
            <ConfirmTemplate confirm={confirm} setConfirm={setConfirm} />
        </TemplateContext.Provider>
    );
}

export default TemplateProvider;
