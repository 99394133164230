export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function getFloatingMessage() {
    if (process.env.REACT_APP_FLOATING_ST === false) {
        return null;
    }

    return {
        status: true,
        message: process.env.REACT_APP_FLOATING_MESSAGE
    };
}

export function getShopId() {
    return process.env.REACT_APP_SHOP_ID;
}

export function getTitle() {
    return process.env.REACT_APP_COMPANY_TITLE;
}

export function getWebUrl() {
    return isDev ? process.env.REACT_APP_DEMO_WEB_URL : process.env.REACT_APP_LIVE_WEB_URL;
}

export function getApiUrl() {
    return isDev ? process.env.REACT_APP_DEMO_API_URL : process.env.REACT_APP_LIVE_API_URL;
}

export function getHomeUrl() {
    return process.env.REACT_APP_URL_HOME;
}

export function getLoginUrl() {
    return isDev ? process.env.REACT_APP_DEMO_LOGIN_URL : process.env.REACT_APP_LIVE_LOGIN_URL;
}

export function getNotificationUrl() {
    return process.env.REACT_APP_URL_NOTIFY;
}

export function getFirebaseConfig() {
    return {};
}

export default null;
