import axios from 'axios';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getApiUrl } from '../../utils/Env';

const InitContext = createContext();

export function useInit() {
    return useContext(InitContext);
}

export const getInitId = () => localStorage.getItem('init-id') || '';

function InitProvider({ children }) {
    const [initInfo, setInitInfo] = useState(null);

    const setInit = (data) => {
        if (data.error === 0) {
            // --Update Local Storage
            localStorage.setItem('init-id', data.data.initId);

            // --Update Store
            setInitInfo(data.data);
        }
    };

    useEffect(() => {
        axios
            .get(`${getApiUrl()}/api/initialize/init/`, {
                headers: {
                    'Init-Id': getInitId()
                }
            })
            .then(({ data }) => {
                setInit(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const value = useMemo(() => ({ initInfo, setInitInfo }), [initInfo]);

    if (initInfo === null) {
        return null;
    }

    return <InitContext.Provider value={value}>{children}</InitContext.Provider>;
}

export default InitProvider;
