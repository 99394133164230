import React from 'react';

const Header = ({ title }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">{title}</div>
            <div className="modal-box-close">
                <button className="close" type="button" />
            </div>
        </div>
    </div>
);

const Body = ({ children }) => (
    <div className="modal-box-body">
        <div>{children}</div>
    </div>
);

function LibModal({ children, title, header }) {
    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                {header || <Header title={title} />}

                <Body>{children}</Body>
            </div>
        </div>
    );
}

export default LibModal;
