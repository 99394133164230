/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

const eyeOpened = 'https://files.bikiran.com/assets/images/icon/icon-pass-show.svg';
const eyeClosed = 'https://files.bikiran.com/assets/images/icon/icon-pass-hide.svg';

export function TextInputs({ label, name, type = 'text', placeholder, value, onChange }) {
    return (
        <label className="sec-input">
            <span>{label}</span>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </label>
    );
}

export function PasswordInputs({ name, label, placeholder = '••••••••••••', value, onChange }) {
    const [eyeSt, setEyeSt] = useState({
        type: 'password',
        icon: eyeClosed
    });

    const handleEyeClick = () => {
        setEyeSt((d) => {
            if (d.type === 'password') {
                return { type: 'text', icon: eyeOpened };
            }

            return { type: 'password', icon: eyeClosed };
        });
    };

    return (
        <label className="sec-input">
            <span>{label}</span>
            <div>
                <input
                    type={eyeSt.type}
                    name={name}
                    className="fill"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
                <button type="button" onClick={handleEyeClick} className="end-icon">
                    <img src={eyeSt.icon} alt="icon" />
                </button>
            </div>
        </label>
    );
}

export default null;
