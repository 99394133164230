import {
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import { firebaseAuth } from '../../MyFirebase';

export function signInWithEmail(email, password) {
    return new Promise((resolve, reject) => {
        console.log(email, password);
        signInWithEmailAndPassword(firebaseAuth, email, password)
            .then(({ user }) => {
                console.log(user);
                resolve(user);
            })
            .catch(() => {
                reject(Error('Login failed.'));
            });
    });
}

export function signInWithGoogle() {
    return new Promise((resolve, reject) => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        signInWithPopup(firebaseAuth, provider)
            .then(({ user }) => {
                resolve(user);
            })
            .catch(() => {
                reject(Error('Login failed.'));
            });
    });
}

export function signInWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');

    return new Promise((resolve, reject) => {
        signInWithPopup(firebaseAuth, provider)
            .then(({ user }) => {
                resolve(user);
            })
            .catch(() => {
                reject(Error('Login failed.'));
            });
    });
}

export function signInWithGithub() {
    const provider = new GithubAuthProvider();
    provider.addScope('user:email');

    return new Promise((resolve, reject) => {
        signInWithPopup(firebaseAuth, provider)
            .then(({ user }) => {
                resolve(user);
            })
            .catch(() => {
                reject(Error('Login failed.'));
            });
    });
}

export function firebaseSignOut() {
    return new Promise((resolve, reject) => {
        signOut(firebaseAuth)
            .then(() => {
                resolve({ error: 0, message: 'Signed out successfully' });
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
