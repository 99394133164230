import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../../../configs/Images';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { PasswordInputs, TextInputs } from '../../../lib/LibInputs';
import LibModal from '../../../lib/LibModal';
import { useAuth } from '../AuthProvider';

const socialParams = {
    google: {
        icon: 'https://files.bikiran.com/assets/images/icon/icon-google-w.svg'
    },
    facebook: {
        icon: 'https://files.bikiran.com/assets/images/icon/icon-facebook-w.svg'
    },
    github: {
        icon: 'https://files.bikiran.com/assets/images/icon/icon-github-w.svg'
    }
};

function ForgetPass() {
    return (
        <div className="forget-pass">
            <Link to="/account/password/reset/">Forgot Password?</Link>
        </div>
    );
}

function BtnSignIn() {
    return (
        <button className="button button-gr-pink w-100 login-btn" type="submit">
            Sign In
        </button>
    );
}

function Separator() {
    return (
        <div className="separator flex-row align-space-between position-middle">
            <div className="fill">
                <hr />
            </div>
            <div style={{ padding: '0 15px' }}>or</div>
            <div className="fill">
                <hr />
            </div>
        </div>
    );
}

function BtnSocial({ keyName, title, signInWith }) {
    const { setMessage } = useTemplate();

    const handelLogin = () => {
        setMessage(`Logging in with ${title}...`);
        signInWith()
            .then(({ error, message }) => {
                if (error === 0) {
                    setMessage('Logged in successfully');
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <button
            className={`button social-login-btn-${keyName} flex-row align-center position-middle`}
            type="button"
            onClick={handelLogin}
        >
            <img src={socialParams[keyName].icon} alt={title} />
            <span>Sign in with {title}</span>
        </button>
    );
}

function NavToAccCreate() {
    const navigate = useNavigate();

    const handelNavigate = () => {
        navigate('/account/create/by-email/');
    };

    return (
        <div className="text-center">
            <button className="button transparent" type="button" onClick={handelNavigate}>
                <span className="text-g" style={{ color: 'rgb(19, 15, 64)', opacity: '0.5' }}>
                    Don&apos;t have an account?
                </span>
                &nbsp;<span className="text-g">Sign Up</span>
            </button>
        </div>
    );
}

function ModalBody() {
    const { setMessage } = useTemplate();
    const { signInEmail, signInGoogle, signInFacebook, signInGithub } = useAuth();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handelOnSubmit = (ev) => {
        ev.preventDefault();
        setMessage(`Logging in with Email...`);
        signInEmail(formData.email, formData.password)
            .then(({ error, message }) => {
                if (error === 0) {
                    setMessage('Logged in successfully');
                } else {
                    setMessage(message);
                }
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const handleInputs = (ev) => {
        const { name, value } = ev.target;

        if (name) {
            setFormData((d) => ({ ...d, [name]: value }));
        }
    };

    return (
        <div className="login-modal-area">
            <form action="/" onSubmit={handelOnSubmit}>
                <TextInputs
                    label="Email Address"
                    name="email"
                    placeholder="ex: username@email.com"
                    onChange={handleInputs}
                />

                <PasswordInputs label="Password" name="password" onChange={handleInputs} />

                <ForgetPass />

                <BtnSignIn />
            </form>

            <Separator />

            <BtnSocial keyName="google" title="Google" signInWith={signInGoogle} />

            <BtnSocial keyName="facebook" title="Facebook" signInWith={signInFacebook} />

            <BtnSocial keyName="github" title="Github" signInWith={signInGithub} />

            <NavToAccCreate />
        </div>
    );
}

function CustomHeader() {
    return (
        <div className="custom-modal-header text-center">
            <img src={images.logoBikiran} alt="Bikiran logo" className="" />
            <div className="modal-box-title">Login to Bikiran Account</div>
        </div>
    );
}

function LoginPage() {
    return (
        <div>
            <LibModal title="Login" header={<CustomHeader />}>
                <ModalBody />
            </LibModal>
        </div>
    );
}

export default LoginPage;
