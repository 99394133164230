import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import URLParse from 'url-parse';
import { firebaseAuth } from '../../MyFirebase';
import { getInitId } from '../../features/init/InitProvider';
import { getApiUrl } from '../../utils/Env';
import {
    firebaseSignOut,
    signInWithEmail,
    signInWithFacebook,
    signInWithGithub,
    signInWithGoogle
} from './AuthFirebase';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const initialState = {
    loading: true,
    refreshToken: null,
    userUid: null,
    currentUser: {
        name: '',
        username: '',
        gender: '',
        genderTxt: '',
        dob: 0,
        dobText: '',
        phone: '',
        email: '',
        photoUrl: ''
    },
    error: 1,
    message: ''
};

const verifyLogin = (user, setAuthInfo) =>
    new Promise((resolve, reject) => {
        const { uid, refreshToken } = user;
        axios
            .post(`${getApiUrl()}/auth/login/verify/`, {
                userUid: uid,
                refreshToken
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    setAuthInfo({
                        ...initialState,
                        loading: false,
                        error: 0,
                        message: data.message,
                        userUid: uid,
                        refreshToken,
                        currentUser: {
                            name: data.user.displayName,
                            username: data.user.username,
                            gender: data.user.gender,
                            genderTxt: '',
                            dob: data.user.dob,
                            dobText: '',
                            phone: data.user.phone,
                            email: data.user.email,
                            photoUrl: data.user.photoUrl
                        }
                    });
                    resolve(data);
                } else {
                    setAuthInfo({
                        ...initialState,
                        loading: false,
                        error: data.error,
                        message: data.message,
                        currentUser: initialState.currentUser
                    });
                    reject(data.message);
                }
            });
    });

const pickUsername = (email, type) =>
    new Promise((resolve, reject) => {
        axios
            .post(
                `${getApiUrl()}/auth/login/get-id/`,
                {
                    email,
                    type
                },
                {
                    headers: {
                        'Init-Id': getInitId()
                    }
                }
            )
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(data.message);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

function AuthProvider({ children }) {
    const [authInfo, setAuthInfo] = useState(initialState);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                verifyLogin(user, setAuthInfo);
            } else {
                setAuthInfo((d) => ({ ...d, loading: false }));
            }
            unsubscribe();
        });
    }, []);

    // --Redirect after success login
    useEffect(() => {
        if (authInfo.loading === false && authInfo.error === 0) {
            // --Go to Home
            const url = new URLParse(window.location.href, true);
            const cont = url.query.continue;
            if (cont) {
                window.location.href = cont;
            }
        }
    }, [authInfo]);

    const value = useMemo(() => {
        const signInEmail = (email, password) =>
            new Promise((resolve, reject) => {
                (async () => {
                    const P0 = await pickUsername(email, 'email');
                    const P1 = await signInWithEmail(P0.userEmail, password || '****');
                    const P2 = await verifyLogin(P1, setAuthInfo);

                    resolve(P2);
                })().catch((err) => {
                    reject(err);
                });
            });

        const signInGoogle = () =>
            new Promise((resolve, reject) => {
                (async () => {
                    const P1 = await signInWithGoogle();
                    const P2 = await verifyLogin(P1, setAuthInfo);

                    resolve(P2);
                })().catch((err) => {
                    reject(err);
                });
            });

        const signInFacebook = () =>
            new Promise((resolve, reject) => {
                (async () => {
                    const P1 = await signInWithFacebook();
                    const P2 = await verifyLogin(P1, setAuthInfo);

                    resolve(P2);
                })().catch((err) => {
                    reject(err);
                });
            });

        const signInGithub = () =>
            new Promise((resolve, reject) => {
                (async () => {
                    const P1 = await signInWithGithub();
                    const P2 = await verifyLogin(P1, setAuthInfo);

                    resolve(P2);
                })().catch((err) => {
                    reject(err);
                });
            });

        // todo: Update Sign Out log to Bikiran
        const signOut = () => firebaseSignOut();

        return { authInfo, signInEmail, signInGoogle, signInFacebook, signInGithub, signOut };
    }, [authInfo]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
