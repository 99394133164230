import '../assets/style.css';
import RouteHandler from './RouteHandler';
import AuthProvider from './app/auth/AuthProvider';
import InitProvider from './features/init/InitProvider';
import TemplateProvider from './features/template/TemplateProvider';
import ComposeProviders from './utils/ComposeProviders';

function App() {
    return (
        <ComposeProviders components={[TemplateProvider, InitProvider, AuthProvider]}>
            <RouteHandler />
        </ComposeProviders>
    );
}

export default App;
