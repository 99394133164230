import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccLinkVendorPage from './app/auth/link/AccLinkVendorPage';
import LoginPage from './app/auth/login/LoginPage';
import AccPasswordResetPage from './app/auth/passwords/AccPasswordResetPage';
import AccCreateByEmailPage from './app/auth/registration/AccCreateByEmailPage';
import AccCreateByVendorPage from './app/auth/registration/AccCreateByVendorPage';
import CrossPage from './app/cross/CrossPage';
import NotFoundPage from './app/system/NotFoundPage';

function RouteHandler() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/i/" element={<CrossPage />} />
                <Route path="/account/login/" element={<LoginPage />} />
                <Route path="/account/create/by-email/" element={<AccCreateByEmailPage />} />
                <Route path="/account/create/by-vendors/" element={<AccCreateByVendorPage />} />
                <Route path="/account/password/reset/" element={<AccPasswordResetPage />} />
                <Route path="/account/add/vendor/" element={<AccLinkVendorPage />} />

                <Route path="/*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
}
export default RouteHandler;
